import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import Benefit from "../../components/common/Benefit-box/Benefit.js"
import Buttons from "../../components/common/button.js"
import CTA from "../../components/common/CTA.js"
import Frame from "../../components/common/frame.js"
import MarqueeImages from "../../components/common/MarqueeImages/MarqueeImages.js"
import ScrollCardNavTabs from "../../components/common/Scrolling-container/ScrollCardNavTabs.js"
import TestimonialV7 from "../../components/common/testimonial-component/multiple-7.js"
import VerticalTabs from "../../components/common/VerticalTabComponent/VerticalTabs.js"
import Navigation from "../../components/navigation.js"
import "../../styles/pages/it-home-24.scss"

const ITLandingPage = () => {
  const data = useITHome24Query()
  const { seo, newField, ctaBoxes, pageImages } = data.SuperOps.pages[0]
  const benefitsData = newField[1]
  const marqueeData = newField[2]
  const itTeamCards = newField[3]
  const faqData = newField[5]
  const testimonialData = newField[6]
  const colabsData = newField[8]
  const integration1 = newField[9]
  const integration2 = newField[10]

  return (
    <div className="it-home-24" style={{ background: "#F6F0E6" }}>
      <Frame
        title={seo?.title}
        description={seo?.description}
        ogType="website"
        footerClass={"p-0"}
        newButton
      >
        <header>
          <Navigation page="Home" dskScroll={400} newButton />
        </header>

        <section className="hero prelative">
          <div className="layout-box mx-auto">
            <div className="d-flex align-items-center justify-content-center mb24">
              <p className="font-white p16 fw-600 tag m-0 down-to-up-1">
                {newField[0].tag}
              </p>
            </div>

            <h1 className="down-to-up-2 font-roboto fw-bold font-white heading mx-auto text-center mb30">
              {newField[0].heroExcerptList[0]}
              <span className="bg-font">{newField[0].heroExcerptList[1]}</span>
            </h1>

            <p className="down-to-up-3 font-white p18 description mx-auto text-center">
              {newField[0].headingLine1}
            </p>

            <div className="hero-points down-to-up-4">
              {newField[0].lists.map(point => {
                return (
                  <div className="point">
                    <img src={newField[0].images[0].url} />
                    <p className="text">{point}</p>
                  </div>
                )
              })}
            </div>

            <div className="hero-btns down-to-up-5">
              <Buttons
                theme={`primary-new`}
                text={newField[0].primaryButtonText}
                link={newField[0].primaryButtonLink}
              />

              <Buttons
                theme={`secondary-new`}
                text={newField[0].secondaryButtonText}
                link={newField[0].secondaryButtonLink}
              />
            </div>
          </div>

          <div className="hero-img mx-auto down-to-up-4">
            <img src={newField[0].heroImage.url} className="d-dsk w-100" />
            <img src={newField[0].heroImageMob.url} className="d-mob w-100" />
          </div>
        </section>

        <section className="marquee-images-container">
          <MarqueeImages
            heading={marqueeData.title[0]}
            images={marqueeData.image}
            newOpacity
          />
        </section>

        <section className="benefit-container">
          <Fade bottom delay={150} duration="1000">
            <div className="title">
              <p>{benefitsData.heading[0]}</p>
            </div>

            <div className="bg-clr" />

            <Benefit
              headingHtml={benefitsData.content}
              icon={benefitsData.image}
              description={benefitsData.subtext}
              iconClassName="icon-stylings"
              iconBcgColor={benefitsData.text}
            />
          </Fade>
        </section>

        <section className="integration-container">
          <Fade bottom delay={150} duration="1000">
            <Container className="Layout-container d-flex justify-content-center flex-wrap gap-40 align-items-center">
              <p className="title m-0">{integration1.heading[0]}</p>

              <Buttons
                theme={`secondary-new demo-btn-left`}
                text={integration1.title[0]}
                link={integration1.links[0]}
              />
            </Container>
          </Fade>
        </section>

        <section className="scrolling-tabs-container">
          <ScrollCardNavTabs data={newField[7]} isPlayOnViewPort />
        </section>

        <section className="feature-cta-1-wrapper">
          <CTA
            data={[ctaBoxes[0]]}
            className="Layout-container"
            lgLeft={6}
            lgRight={4}
            newButton
            isSecondaryBtn
            newDesign
          />
        </section>

        <section className="it-ops-card-container">
          <Fade bottom delay={150} duration="1000">
            <div className="bg-clr"></div>
            <Container className="Layout-container">
              <div className="heading">{itTeamCards.heading}</div>

              <div className="cards-container">
                <Row className="justify-content-center">
                  {itTeamCards.title.map((item, idx) => {
                    return (
                      <Col lg={5}>
                        <div className="card">
                          <p className="title">{item}</p>

                          {parse(itTeamCards.content[idx].html)}

                          <img src={itTeamCards.image[idx].url} />
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </Container>
          </Fade>
        </section>

        <VerticalTabs data={faqData} />

        <section className="integration-container ic2">
          <Fade bottom delay={150} duration="1000">
            <Container className="Layout-container d-flex justify-content-center flex-wrap gap-40 align-items-center">
              <p className="title m-0">{integration2.heading[0]}</p>

              <Buttons
                theme={`secondary-new demo-btn-left`}
                text={integration2.title[0]}
                link={integration2.links[0]}
              />
            </Container>
          </Fade>
        </section>

        <section className="colabs-container">
          <Container className="Layout-container">
            <Fade bottom delay={150} duration="1000">
              <div className="content">
                <p className="note">{colabsData.heading[0]}</p>
                <p className="title">{colabsData.title[0]}</p>
              </div>

              <div className="colabs-img mx-auto">
                <img src={pageImages[0].url} className="d-dsk w-100" />
                <img src={pageImages[1].url} className="d-mob w-100" />
              </div>
            </Fade>
          </Container>
        </section>

        <section className="testimonial-wrapper">
          <LazyLoad>
            <Fade bottom duration={1000}>
              <TestimonialV7 testimonial={testimonialData} />
            </Fade>
          </LazyLoad>
        </section>

        <section className="feature-cta-2-wrapper">
          <CTA
            data={[ctaBoxes[1]]}
            className="Layout-container"
            lgLeft={6}
            lgRight={4}
            newButton
            newDesign
            isSecondaryBtn
          />
        </section>
      </Frame>
    </div>
  )
}

export default ITLandingPage

const useITHome24Query = () => {
  const data = useStaticQuery(graphql`
    query itLandingPageQuery {
      SuperOps {
        pages(where: { title: "IT-Home-24" }) {
          title
          linkTitle
          pageImages {
            url
          }
          navigationBlock {
            name
            slug
            isDropdown
          }
          seo {
            title
            description
            keywords
            image {
              url
            }
          }
          navigationBlock {
            colors {
              hex
            }
            where
            dropdownHeader
            navigationLinks {
              heading
              subText
              link
              image {
                url
              }
              childFooter
              childFooterLink
              featureNavigationLinks {
                heading
                subText
                link
                icon {
                  url
                }
                iconBgColor {
                  css
                }
                childHeader
              }
            }
          }
          ctaBoxes {
            type
            theme
            customBackgroundColor
            heading {
              html
            }
            description {
              html
            }
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
            card {
              cardName
              title
              subtext
              slug
            }
          }
          newField {
            ... on SuperOps_Hero {
              lists
              heroExcerptList
              headingLine1
              tag
              heroImage {
                url
              }
              heroImageMob {
                url
              }
              images {
                url
              }
              primaryButtonText
              primaryButtonLink
              secondaryButtonText
              secondaryButtonLink
              heroImageMob {
                url
              }
            }
            ... on SuperOps_Testimonial {
              testimonialDatas {
                title
                name
                heading
                content
                bgColor
                image {
                  url
                }
                icon {
                  url
                }
              }
              testimonialSectionHeading
              testimonialTitle
              testimonialName
              testimonialDescription
              testimonialContent
              testimonialLink
              secondaryBtnText
              secondaryBtnLink
              testimonialImage {
                url
              }
            }
            ... on SuperOps_Card {
              title
              heading
              cardName
              cardDescription
              slug
              links
              text
              content {
                html
              }
              subtext
              image {
                url
              }
            }

            ... on SuperOps_FaqComponent {
              __typename
              faqHeading: heading
              faqDescription: description
              ctaText {
                text
                html
              }
              faqType
              buttonText: text
              link
              faqs {
                illustrationPattern
                question
                answerrt {
                  html
                  text
                }
                description
                linkText
                slug
                images {
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
