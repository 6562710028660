import { css } from "@emotion/react"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import { Link as ScrollLink } from "react-scroll"
import Block from "../../../components/common/Block/Block"
import SVGIcon from "../SVGIcon"
import "./ScrollCardNavTabs.scss"

const darkColors = ["#FE7D7D", "#D67FBF", "#8E97E1", "#B0E9A4"]
const lightColors = ["#FFC3C3", "#FFD1F3", "#D4D9FF", "#e0ffd9"]

const ScrollCardNavTabs = ({ data, isPlayOnViewPort }) => {
  const videoRefs = useRef([])

  const [activeName, setActiveName] = useState(data.title[0])

  const handleTabChange = (name, i) => {
    setActiveName(name)
  }

  useEffect(() => {
    const handleIntersection = entries => {
      entries.forEach(entry => {
        const video = entry.target

        if (entry.isIntersecting) {
          video.play()
        } else {
          video.pause()
          video.currentTime = 0
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    })

    videoRefs.current.forEach(video => {
      if (video) {
        observer.observe(video)
      }
    })

    return () => {
      videoRefs.current.forEach(video => {
        observer.unobserve(video)
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const scrollContainer = document
      .getElementById("scroll_nav_tabs")
      ?.getBoundingClientRect()
    if (scrollContainer) {
      const contHeight = scrollContainer?.height
      const contTop = scrollContainer?.top
      const floatingTabs = document.getElementById("floating_tabs")

      if (contTop < 390 && contTop > -1 * (contHeight - 800)) {
        // floatingTabs.style.opacity = 1
        floatingTabs.classList.add("live")
        // floatingTabs.classList.remove("dead")
      } else {
        floatingTabs.classList.remove("live")
        // floatingTabs.classList.add("dead")
        // floatingTabs.style.opacity = 0
      }
    }
  }

  return (
    <>
      <div className="scroll-nav-tabs" id="scroll_nav_tabs">
        <Container className="Layout-container">
          {data.title.map((item, idx) => {
            return (
              <>
                <div id={`id_${item}`} />
                <Block
                  tag={data.text[idx]}
                  description={data.subtext[idx]}
                  image={[data.image[idx]]}
                  imageTitle={[""]}
                  isImage={["no"]}
                  isVideo={["Yes"]}
                  lgLeft={5}
                  lgRight={5}
                  ref={el => {
                    if (isPlayOnViewPort) {
                      videoRefs.current[idx] = el
                    }
                  }}
                />
              </>
            )
          })}
          <div
            id="floating_tabs"
            className={`floating-tabs d-dsk d-flex justify-content-between br10`}
          >
            {data.title.map((name, i) => {
              return (
                <ScrollLink
                  to={`id_${name}`}
                  offset={-300}
                  smooth={true}
                  duration={400}
                >
                  <button
                    className={`resource-button br10 z-1 alt ${name ===
                      activeName && "active"}`}
                    onClick={() => handleTabChange(name, i)}
                    role="button"
                    tabIndex={0}
                    css={css`
                      background: linear-gradient(
                        90deg,
                        ${darkColors[i]} -60%,
                        rgba(255, 255, 255, 0) 80%
                      );
                      &.active {
                        background: linear-gradient(
                          90deg,
                          ${darkColors[i]} 0%,
                          ${lightColors[i]} 103.13%
                        );
                      }
                    `}
                  >
                    <div className="button-name d-flex align-items-center justify-content-between fw-bold">
                      {name.toUpperCase()}
                      <SVGIcon
                        name="home/rightArrBlack"
                        className="right-arrow-black"
                      />
                    </div>
                  </button>
                </ScrollLink>
              )
            })}
          </div>
        </Container>
      </div>
    </>
  )
}

export default ScrollCardNavTabs
